.toastTransition-enter {
  right: -300px;
}

.toastTransition-enter-active {
  right: 0;
  transition: right 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.toastTransition-exit {
  right: 0;
}

.toastTransition-exit-active {
  right: -500px;
  transition: right 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
