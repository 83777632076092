.row {
  position: relative;
  display: flex;
  flex-direction: row;
  transition: 200ms;

  color: inherit;
  background: inherit;
  text-decoration: inherit;

  &:not(:last-of-type):after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: calc(100%);
    border-bottom: 1px solid #e6e6e6;
  }

  &:first-of-type {
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: -1px;
      height: 1px;
      width: calc(100%);
      border-top: 1px solid #e6e6e6;
    }
  }
}

.headerRow {
  position: relative;
  display: flex;
  flex-direction: row;
  transition: 200ms;
  align-items: center;
  width: 100%;
}
