/* purgecss start ignore */
@tailwind base;

html,
body,
#__next {
  height: 100%;
  font-family: Inter, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  font-size: 16px;
  line-height: 26px;
}

/* add base styles here */
@tailwind components;

/* latin */
@font-face {
  font-family: 'JetBrains Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../public/fonts/JetBrainsMono-400-latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'JetBrains Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../public/fonts/JetBrainsMono-400-latinext.woff2')
    format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../../public/fonts/Inter-300-latinext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../../public/fonts/Inter-300-latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../public/fonts/Inter-400-latinext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../public/fonts/Inter-400-latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../public/fonts/Poppins-400-latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../../public/fonts/Inter-400-latinext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../public/fonts/Poppins-500-latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../public/fonts/Poppins-500-latinext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../../public/fonts/Poppins-600-latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../../public/fonts/Poppins-600-latinext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../../public/fonts/Poppins-700-latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../../public/fonts/Poppins-700-latinext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

hr {
  @apply border-grey-300;
}

/* purgecss end ignore */
@tailwind utilities;

@responsive {
  /* heading styles */
  .heading-d1 {
    @apply font-display text-primary-darker font-bold text-60;
  }

  .heading-d2 {
    @apply font-display text-primary-darker font-bold text-39;
  }

  .heading-h1 {
    @apply font-display text-primary-darker font-medium text-31;
  }

  .heading-h2 {
    @apply font-display text-primary-darker font-medium text-24;
  }

  .heading-h3 {
    @apply font-display text-primary-darker font-medium text-21;
  }

  .heading-h4 {
    @apply font-display text-primary-darker font-medium text-16 leading-19;
  }
}

/* React tooltip padding override */
.padding-small {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

/* Prevents React tooltip FOUC */
.__react_component_tooltip {
  position: fixed;
  opacity: 0 !important;

  &.show {
    opacity: 1 !important;
  }
}

/* misc */
.cursor-help {
  cursor: help;
}

.outline-offset-8 {
  outline-offset: '8px';
}

.gradient-fade-right {
  background: 'linear-gradient(90deg, rgba(255,255,255, 0) 20%, #fff 60%)';
}

/* JSON code hightlight classes */
/* TODO clean unused classes */

.hljs {
  display: block;
  overflow-x: auto;
}
.hljs-addition,
.hljs-meta,
.hljs-string,
.hljs-symbol,
.hljs-template-tag,
.hljs-template-variable {
  color: #c3e88d;
}
.hljs-comment,
.hljs-quote {
  color: #636363;
}
.hljs-bullet,
.hljs-link,
.hljs-literal,
.hljs-number,
.hljs-regexp {
  color: #c792ea;
}
.hljs-deletion,
.hljs-variable {
  color: #c792ea;
}
.hljs-built_in,
.hljs-doctag,
.hljs-keyword,
.hljs-name,
.hljs-section,
.hljs-selector-class,
.hljs-selector-id,
.hljs-selector-tag,
.hljs-strong,
.hljs-tag,
.hljs-title,
.hljs-type {
  color: #82aaff;
}
.hljs-emphasis {
  font-style: italic;
}
.hljs-attribute {
  color: #f07178;
}

.form-mono-font input,
.form-mono-font select {
  font-family: 'JetBrains Mono';
  font-size: 14px;
}

.box-decoration-clone {
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}

mark {
  background-color: transparent;
  color: #0f6dfa;
}

.markdown-header-copy:hover .markdown-header-copy-button {
  opacity: 1;
}

.markdown-header-copy-button {
  opacity: 0;
}
