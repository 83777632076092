.jira {
  overflow-x: auto;

  table {
    width: 100%;
  }

  th,
  td {
    text-align: left;
    padding: 10px;
    border: 1px solid #c1c7d0;
  }

  th {
    background: #f4f5f7;
  }

  a {
    color: #0366d6;
  }

  a:hover {
    text-decoration: underline;
  }

  h2 {
    font-weight: 600;
    font-size: 20px;
  }
}
