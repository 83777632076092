.overlay {
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  overflow: auto;
  display: flex;
  z-index: 40;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  -ms-overflow-style: none;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  scrollbar-gutter: stable both-edges;
}

.afterOpen {
  opacity: 1;
}

.beforeClose {
  opacity: 0;
}

.content {
  cursor: auto;
  outline: none;
  max-width: 100%;
  display: flex;
  margin: auto;
  padding: 50px 0;
}

.htmlOpen {
  overflow: hidden;
}

.lightbox {
  outline: none;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
