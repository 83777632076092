.footer {
}

.footer p {
  @apply flex flex-col;
}

.footer a {
  @apply text-white-100 leading-40;
}
